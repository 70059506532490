import { Cinema } from "../cinema-module/cinema";
import { StorefrontCinema } from "./storefront-cinema";
import { ScarcityCountdown } from "../cinema-module/scarcity-countdown";
import { getFeatureOrder } from "./helper";

const cinema = new Cinema(new ScarcityCountdown());

const benefitCinemas = document.getElementById("ft9_benefit_cinema__root_marker");
cinema.init(getFeatureOrder(benefitCinemas));

const storefrontCinema = new StorefrontCinema();
storefrontCinema.init();
